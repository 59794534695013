import { Outlet, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Outlet />
      <footer>
        &copy; 2023 <a href="mailto:hello@tuze.ai">hello@tuze.ai</a>
        &nbsp;&middot;&nbsp;
        <Link
          to="https://help.tuze.app/article/7-tuze-privacy-policy"
          target={"_hs"}
        >
          Privacy Policy
        </Link>
        &nbsp;&middot;&nbsp;
        <Link to="https://help.tuze.app/article/6-tuze-tos" target={"_hs"}>
          Terms of Service
        </Link>
        <p>
          * Past performance is no guarantee of future success. Beware small
          sample sizes. Leave the campsite better than you found it.
        </p>
      </footer>
    </>
  );
};

export default Footer;
